import { Http } from '@/apis/Http'

export const SearchLeasObjectApi = {
  fetchCreatingDetails() {
    return Http.get('/search-leas-object/creating-details')
  },

  fetchTenPopularCities() {
    return Http.get('/search-leas-object/get-ten-popular-cities')
  },

  fetchTenPopularCarBrands(withMileage: any, isTruck: any) {
    return Http.get('/search-leas-object/get-ten-popular-car-brands',
    {
      params: {
        withMileage,
        isTruck
      }
    }
    )
  },

  getRequestList(page, perPage, search, sortBy, sortDesc) {
    return Http.get('/search-leas-object/list', {
      params: {
        page,
        perPage,
        search,
        sortBy,
        sortDesc
      }
    })
  },
  getArchivedRequestList(page, perPage, search, sortBy, sortDesc) {
    return Http.get('/search-leas-object/archived', {
      params: {
        page,
        perPage,
        search,
        sortBy,
        sortDesc
      }
    })
  }
}
